<template>
  <attendance :isReport="false" />
</template>
<style scoped></style>
<script>
import Attendance from "./../../../../components/Attendance";

export default {
  data: () => ({}),
  components: { Attendance },
  watch: {},
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped></style>
